import React, { FC, memo, useState } from 'react';
import { EventDto } from 'stores/events/events-store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DateTimeService } from 'services/datetime-service';
import { Button, Icon } from 'semantic-ui-react';
import SchedulerViewModal from 'widgets/scheduler/scheduler-view-modal';

export interface PlannerAssistantEventsSuggestionProps extends WithTranslation {
  onAcceptSuggestionClick?: (value: EventDto) => void;
  event: EventDto;
  hide: boolean;
}

/*Based on SchedulerEventTooltip*/
const PlannerAssistantEventsSuggestion: FC<PlannerAssistantEventsSuggestionProps> = ({ event, hide, t, onAcceptSuggestionClick }) => {
  const [showCalendarView, setShowCalendarView] = useState(false);

  if (!event) return;
  const { eventDetails, eventTrainingDetails, eventType, friendlyId, status, title, ...rest } = event;
  const { startDate, endDate, instructors, warningMessages } = rest;

  const onClick = (event: EventDto) => {
    onAcceptSuggestionClick(event);
  };

  const hexToRgba = (hex: string, alpha: number): string => {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const handleShowCalendarViewModal = () => {
    setShowCalendarView(true);
  };

  const handleHideCalendarViewModal = () => {
    setShowCalendarView(false);
  };

  return (
    <div className="planner-assistant-wrapper" hidden={!hide} style={{ backgroundColor: hexToRgba(eventType.eventsColor, 0.15) }}>
      <div className="header">
        {(instructors || []).map(({ instructor: { firstName, lastName }, instructorId, locationId }) => (
          <div key={instructorId + locationId} className="header-section">
            <div className="header-section-item">
              <i className="b-fa b-fa-user"></i>
              <p className="instructor-info__name">
                {lastName}, {firstName}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="section">
        <div className="section-item">
          <i className="b-fa b-fa-circle" style={{ color: eventType.eventsColor }} />
          <p className="first-row__content">{eventType.name}</p>
        </div>
      </div>
      <div className="section">
        <div className="section-item">
          <i className="b-fa b-fa-file"></i>
          <p className="event-code">{title}</p>
        </div>
      </div>
      <div className="section">
        <div className="section-item">
          <i className="b-fa b-fa-calendar-alt"></i>
          <p className="date">
            {DateTimeService.toDateInputString(startDate)} - {DateTimeService.toDateInputString(endDate)}
          </p>
        </div>
        {eventTrainingDetails?.deliveryMethod && (
          <div className="section-item">
            <i className="b-fa b-fa-graduation-cap"></i>
            <p className="delivery-method">{eventTrainingDetails.deliveryMethod.name}</p>
          </div>
        )}
      </div>
      {eventDetails && (
        <div className="section">
          {eventDetails?.location && (
            <div className="section-item">
              <i className="b-fa b-fa-map-marker-alt"></i>
              <p className="event-location-origin">
                {eventDetails?.location?.location} ({eventDetails?.location?.countryName})
              </p>
            </div>
          )}
        </div>
      )}

      {(warningMessages || []).map((warningMessage, index) => (
        <div key={'warnmsg' + index} className="section">
          <div className="section-item">
            <Icon name="exclamation triangle" />
            <p className="event-code">{warningMessage}</p>
          </div>
        </div>
      ))}

      <div className="section">
        <div className="section__btns" style={{ width: 'fit-content', margin: '0 auto', padding: '0px 25px' }}>
          <Button onClick={handleShowCalendarViewModal} secondary inverted>
            {t('Calendar View')}
          </Button>
          <Button className="submit-btn" onClick={_ => onClick(event)}>
            {t('Accept Suggestion')}
          </Button>
        </div>
      </div>

      {showCalendarView && <SchedulerViewModal onClose={handleHideCalendarViewModal} event={event} open={showCalendarView} />}
    </div>
  );
};

export default withTranslation()(memo(PlannerAssistantEventsSuggestion));
