import { RequestsStore, NewRequestStore, ChangeRequestStore } from './requests-store';
import { RequestChatStore } from './requests-chat-stores';
import { RequestWizardStore } from './create-request-wizard-store';
import { RequestFormStore } from './request-form-store';

export function setupRepositories(storeBuilder) {
  storeBuilder.addRepository(new RequestsStore());
  storeBuilder.addRepository(new NewRequestStore());
  storeBuilder.addRepository(new ChangeRequestStore());
  storeBuilder.addRepository(new RequestWizardStore());
  storeBuilder.addRepository(new RequestFormStore());

  storeBuilder.addRepository(new RequestChatStore());
}
