import { ValidationFailure, ValidationResult } from 'fluent-ts-validator';
import i18n from 'i18n';
import { repository } from 'redux-scaffolding-ts';
import { DeliveryTypeDto } from 'stores/configuration/events-n-requests/delivery-types-store';
import { PriorityDto } from 'stores/configuration/events-n-requests/priorities-store';
import { TrainingLevelDto } from 'stores/configuration/events-n-requests/training-levels-store';
import { EventTypeDto, EventFieldGroups } from 'stores/configuration/events-workflow/event-types-store';
import { LanguageDto } from 'stores/configuration/locations/languages-store';
import { LocationDto } from 'stores/configuration/locations/locations-store';
import { DataStore, QueryResult, Query } from 'stores/dataStore';
import { FormStore } from 'stores/formStore';
import { BaseDto } from 'stores/types';
import { RequestDto, Categories, RequestMachinesDto, RequestStatus } from 'stores/requests/requests-store';
import { CustomizationDataDto } from 'stores/configuration/events-n-requests/customization-data-store';
import { nameof } from 'utils/object';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import { StudentDto } from 'stores/students/student-model';
import ExtendedAbstractValidator from '../../utils/extended-abstract-validator';
import { DateTimeService } from 'services/datetime-service';
import {
  hasEventPositions,
  hasInstructors,
  hasSupportDetails,
  instructorsAnyMergedSupportPositionsIncludesSupportPositionId,
  isGEA,
  isGMA
} from 'utils/event-type-utils';
import { UserDto } from 'stores/users/users-store';
import { PillarDto } from 'stores/configuration/profiles/pillars-store';
import { guidIsNullOrEmpty } from 'utils/useful-functions';

export enum EventStatus {
  Draft = 10,
  Planned = 20,
  InProgress = 30,
  Completed = 40,
  Closed = 50
}

export enum EventInstructorRole {
  Lead = 10,
  Apprentice = 20
}

export interface EventDetailsDto {
  priorityId: string;
  priority: PriorityDto;
  locationId: string;
  location: LocationDto;
  category: Categories;
  professionId: string;
  profession: ProfessionDto;
  isMachineRelated: boolean;
  patternId: string;
  patternName: string;
  requestedMachines: RequestMachinesDto[];
  nmrClusterId: string;
  nmrClusterName: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalAreaName: string;
  nmrFunctionalSubAreaId: string;
  nmrFunctionalSubAreaName: string;
  nmrTrainingNameId: string;
  nmrTrainingNameName: string;
}

export interface EventUnblockButtonsDto {
  id: string;
  unblockedForeignResourcesForPlannerMTC: boolean;
  unblockedForeignResourcesForPlannerTFT: boolean;
}

export interface EventTrainingDetailsDto {
  deliveryMethodId: string;
  deliveryMethod: DeliveryTypeDto;
  customizationDataId: string;
  customizationData: CustomizationDataDto;
  languageId: string;
  language: LanguageDto;
  numStudentsRequested?: number;
  trainingLevelId: string;
  trainingLevel: TrainingLevelDto;
  assignedStudents: StudentDto[];
  numStudentsAssigned?: number;
}

export interface CheckListConfiguration {
  rolesThatCreateCheckLists: string[];
}

export interface EventCheckList {
  eventTypeId: string;
  description: string;
  checkListConfig: CheckListConfiguration;
}

export interface EventDocumentDto {
  id: string;
  mimeType: string;
  title: string;
}

export interface EventDto extends BaseDto {
  id: string;
  locked: boolean;
  friendlyId: string;
  title: string;
  status: EventStatus;
  startDate: string;
  endDate: string;
  travelDays?: DateTimePeriod;
  comments: string;
  eventTypeId: string;
  eventType: EventTypeDto;
  plannedDuration: string;
  eventCreatorId: string;
  parentRequestId: string;
  requests: RequestDto[];
  trainingMaterials: TrainingMaterialDto[];
  unblockedForeignResourcesForPlannerMTC: boolean;
  unblockedForeignResourcesForPlannerTFT: boolean;
  eventDetails: EventDetailsDto;
  eventTrainingDetails: EventTrainingDetailsDto;
  instructors: EventInstructorDto[];
  statusValidation: EventStatusValidation;
  pausePeriods: DateTimePeriod[];
  checkLists: EventCheckList[];
  eventUpdatedFlag: boolean;
  eventWarnings: EventWarningDto[];
  supportDetails: EventSupportDetailsDto;
  supportingDocuments: EventDocumentDto[];
  calculatedEventDurationHours: number;
  allFeedBackFormAnswered: boolean;
  allTheoreticalFormAnswered: boolean;
  allPracticalFormAnswered: boolean;
  warningMessages?: string[];
  fromSuggestion: boolean;
}
export interface EventSupportDetailsDto {
  positionsRequested: number;
  totalCost: number;
  supportPositions: EventSupportPositionsDto[];
  newSupportPositions: NewEventSupportPositionsDto[];
  isNewSupportPositionModel: boolean;
}
export interface EventSupportPositionsDto {
  id: string;
  positionCodeId: string;
  positionCodeName: string;
  positionDescription: string;
  comment: string;
  positionsRequested: number;
  eventPositions: EventPositionDto[];
  requestEndDate: string;
  requestStartDate: string;
}

export interface NewEventSupportPositionsDto {
  id: string;
  supportPositionRoleId: string;
  positionsRequested: number;
  requestEndDate: string;
  requestStartDate: string;
  patternId: string;
  patternName: string;
  machineModels: RequestMachinesDto[];
  requestedManDays: number;
  requestedWorkingManHours: number;
  theoreticalCost: number;
  eventNewModelPositions: EventPositionDto[];
}
export interface EventPositionDto {
  userId: string;
  user: UserDto;
  startDate: string;
  endDate: string;
  actualDays: string;
  actualHours: string;
  actualCost: string;
  userLocationId: string;
  plannedDays?: number;
  travelDays: TravelRangeDto;
  noTravel: boolean;
}

export interface EventStatusValidation {
  validatedByPlanners: string[];
  validatedByInstructors: string[];
  validatedByEmployees: string[];
  validatedByPoC: string[];
  validatedByPoCLocations: string[];
  validatedByFactoryLead: string[];
  validatedByFactoryLeadLocations: string[];
  validatedByPlannersMTC: string[];
}

export interface EventUserDto {
  id: string;
  firstName: string;
  lastName: string;
  location: LocationDto;
  pillar?: PillarDto;
}

export interface DateTimePeriod {
  from: string;
  to: string;
}

export interface EventWarningDto {
  description: string;
}

export interface EventInstructorDto {
  locationId: string;
  location: LocationDto;
  instructorId: string;
  instructor: EventUserDto;
  role: EventInstructorRole;
  period: DateTimePeriod;
  workingDays?: number;
  workingHours?: number;
  travelDays: TravelRangeDto;
  supportPositionId?: string;
  noTravel: boolean;
}

export interface TrainingMaterialDto {
  title: string;
  link: string;
}

export interface CreateTrainingMaterialDto {
  title: string;
  link: string;
}

export interface ChangeTrainingMaterialDto {
  title: string;
  link: string;
}

export interface CreateEventTrainingDetailsDto {
  customizationDataId: string;
  languageId: string;
  deliveryMethodId: string;
  trainingLevelId: string;
  assignedStudentIds: string[];
  numStudentsAssigned?: number;
}

export interface ChangeEventTrainingDetailsDto {
  customizationDataId: string;
  languageId: string;
  deliveryMethodId: string;
  trainingLevelId: string;
  assignedStudentsIds: string[];
  numStudentsAssigned: number;
}

export interface CreateEventInstructorDto {
  locationId: string;
  instructorId: string;
  role: EventInstructorRole;
  period: DateTimePeriod;
  workingDays?: number;
  workingHours?: number;
  travelDays: TravelRangeDto;
  noTravel: boolean;
}

export interface ChangeEventInstructorDto {
  locationId: string;
  instructorId: string;
  role: EventInstructorRole;
  period: DateTimePeriod;
  workingDays?: number;
  workingHours?: number;
  travelDays: TravelRangeDto;
  supportPositionId?: string;
  noTravel: boolean;
}

export interface TravelRangeDto {
  departure: DateTimePeriod;
  arrival: DateTimePeriod;
}

export interface ChangeEventDetailsDto {
  priorityId: string;
  locationId: string;
  category: Categories;
  professionId: string;
  isMachineRelated: boolean;
  patternId: string;
  requestedMachines: RequestMachinesDto[];
  nmrClusterId: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalSubAreaId: string;
  nmrTrainingNameId: string;
}

export enum EventDocumentChangeAction {
  Added = 10,
  Deleted = 20
}

export interface EventDocumentChangeDto {
  id: string;
  path: string;
  title: string;
  action: EventDocumentChangeAction;
}

export interface ChangeEventDto {
  id: string;
  locked: boolean;
  plannedDuration: string;
  calculatedEventDurationHours: number;
  status: EventStatus;
  title: string;
  startDate: string;
  endDate: string;
  travelDays?: DateTimePeriod;
  friendlyId: string;
  comments: string;
  eventTitle: string;
  eventTypeId: string;
  eventType: EventTypeDto;
  trainingMaterials: ChangeTrainingMaterialDto[];
  unblockedForeignResourcesForPlannerTFT?: boolean;
  unblockedForeignResourcesForPlannerMTC?: boolean;
  eventDetails: ChangeEventDetailsDto;
  eventTrainingDetails: ChangeEventTrainingDetailsDto;
  instructors: ChangeEventInstructorDto[];
  pausePeriods: DateTimePeriod[];
  supportDetails: ChangeEventSupportDetailsDto;
  hasStudents: boolean;
  studentsMandatory: boolean;
  eventStatusValidation: ChangeEventStatusValidationDto;
  supportingDocuments: EventDocumentChangeDto[];
  fromSuggestion: boolean;
}

export interface ChangeEventStatusValidationDto {
  validatedByPlanners: string[];
  validatedByInstructors: string[];
  validatedByEmployees: string[];
  validatedByPoC: string[];
  validatedByPoCLocations: string[];
  validatedByFactoryLead: string[];
  validatedByFactoryLeadLocations: string[];
  validatedByPlannersMTC: string[];
}

export interface ChangeEventSupportDetailsDto {
  positionsRequested: number;
  totalCost: number;
  supportPositions: ChangeEventSupportPositionsDto[];
  newSupportPositions: ChangeNewEventSupportPositionsDto[];
  isNewSupportPositionModel: boolean;
}
export interface ChangeEventSupportPositionsDto {
  id: string;
  positionCodeId: string;
  comment: string;
  positionsRequested: number;
  eventPositions: ChangeEventPositionDto[];
}

export interface ChangeNewEventSupportPositionsDto {
  id: string;
  positionsRequested: number;
  eventNewModelPositions: ChangeEventPositionDto[];
}

export interface ChangeEventPositionDto {
  userId: string;
  startDate: string;
  endDate: string;
  actualDays: string;
  actualHours: string;
  actualCost: string;
  travelDays: TravelRangeDto;
  userLocationId: string;
  noTravel: boolean;
}
export interface CloseEventDto {
  id: string;
  items: ChangeEventInstructorDto[];
}

export interface CancelEventDto {
  id: string;
  eventRequestsStatus: RequestStatus;
  rejectReason: string;
  rejectionReasonId: string;
  rejectedById: string;
}

export interface EventValidationUsers {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  hasValidated: boolean;
}

export class DateTimePeriodValidator extends ExtendedAbstractValidator<DateTimePeriod> {
  constructor(dateFrom: Date, dateTo: Date, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIf(x => x.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Period From Date is required'));

    this.validateIf(x => x.to)
      .isNotNull()
      .isDefined()
      .withFailureMessage(i18n.t('Period To Date is required'));

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.from).isSameOrBefore(DateTimeService.toMoment(x.to), 'day'))
      .withFailureMessage(i18n.t('Invalid Period'));
  }
}

export class ChangeEventValidator extends ExtendedAbstractValidator<ChangeEventDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Id is required'));

    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Event title is required'));

    this.validateIf(o => o.plannedDuration)
      .isNotNull()
      .isNotEmpty()
      .fulfills(x => parseFloat(x) !== 0)
      .withFailureMessage(i18n.t('Planned Duration is required'));

    this.validateIfString(o => o.eventDetails.locationId)
      .isNotEmpty()
      .isUuid('4')
      .when(
        x =>
          x.eventDetails != null &&
          x.eventType != null &&
          (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails])
      )
      .withFailureMessage(i18n.t('Event Location is required'));

    this.validateIfString(o => o.eventDetails.professionId)
      .isNotEmpty()
      .isUuid('4')
      .when(
        x =>
          x.eventDetails != null &&
          x.eventType != null &&
          (x.eventType.requestFieldGroups || []).includes(EventFieldGroups[EventFieldGroups.RequestDetails]) &&
          !x.eventType.name.toLocaleLowerCase().includes('reserved')
      )
      .when(x => !x.supportDetails || !x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t('Role is required'));

    this.validateIf(o => o.eventDetails.requestedMachines)
      .isNotEmpty()
      .when(t => t.eventDetails !== null && t.eventDetails.isMachineRelated)
      .when(x => !x.supportDetails || !x.supportDetails?.isNewSupportPositionModel)
      .withFailureMessage(i18n.t('Machine rows are required.'));

    this.validateIfString(x => x.eventDetails.nmrClusterId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x.eventDetails != null && !x.eventDetails.isMachineRelated)
      .withFailureMessage(i18n.t('Cluster is required'));

    this.validateIfString(x => x.eventDetails.nmrFunctionalAreaId)
      .isNotEmpty()
      .isUuid('4')
      .when(x => x.eventDetails != null && !x.eventDetails.isMachineRelated)
      .withFailureMessage(i18n.t('Functional Area is required'));

    this.validateIf(o => o)
      .fulfills(
        x =>
          !x.eventType.requestFieldGroups.includes('EventDetails') ||
          !x.hasStudents ||
          !x.studentsMandatory ||
          x.eventTrainingDetails.numStudentsAssigned > 0
      )
      .withFailureMessage(i18n.t('Number of assigned student should be greater than 0'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(x =>
        x.pausePeriods.every(i => {
          if (i == null) return false;
          const startDate = new Date(x.startDate);
          const endDate = new Date(x.endDate);
          const fromDate = new Date(i.from);
          const toDate = new Date(i.to);

          return (
            new DateTimePeriodValidator(fromDate, toDate, this.addErrors).extendValidate(i).isValid() &&
            +startDate <= +fromDate &&
            +fromDate <= +toDate &&
            +toDate <= +endDate
          );
        })
      )
      .when(x => (x.pausePeriods || []).length > 0)
      .withFailureMessage(i18n.t('At least one Pause Period is wrong.'));

    this.validateIf(x => x.trainingMaterials)
      .fulfills(x => x.map(y => y.title).length === x.distinct((p: ChangeTrainingMaterialDto) => p.title).toArray().length)
      .withFailureMessage(i18n.t('At least one Training Material is repeated'));

    this.validateIfIterable(x => x.instructors)
      .isNotNull()
      .isNotEmpty()
      .when(({ eventType }) => eventType?.instructor !== 'No' && !isGEA(eventType) && !isGMA(eventType))
      .withFailureMessage(i18n.t('At least one instructor is required.'));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(({ instructors, startDate, endDate }) =>
        instructors.every(
          (i, idx) => i != null && new ChangeEventInstructorValidator(startDate, endDate, idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(
        ({ eventType, instructors }) =>
          eventType.instructor !== 'No' && (instructors || []).length > 0 && eventType.requestFieldGroups.all(x => x !== 'SupportDetails')
      )
      .withFailureMessage(i18n.t(''));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(({ instructors, startDate, endDate }) =>
        instructors.every(
          (i, idx) =>
            i != null &&
            new ChangeEventWithSupportDetailsInstructorValidator(startDate, endDate, idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(
        ({ eventType, instructors, supportDetails }) =>
          (hasInstructors(eventType) &&
            hasSupportDetails(eventType) &&
            !hasEventPositions(supportDetails) &&
            (instructors || []).length > 0) ||
          (hasInstructors(eventType) &&
            hasSupportDetails(eventType) &&
            hasEventPositions(supportDetails) &&
            (instructors || []).length > 0 &&
            (instructors.any(x => x.supportPositionId == null) ||
              instructorsAnyMergedSupportPositionsIncludesSupportPositionId(instructors, supportDetails)))
      )
      .withFailureMessage(i18n.t(''));

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(({ supportDetails, startDate, endDate }) =>
        supportDetails.newSupportPositions.every(
          (i, idx) =>
            i != null && new ChangeNewSupportPositionValidator(startDate, endDate, idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(
        ({ eventType, supportDetails }) => hasInstructors(eventType) && hasSupportDetails(eventType) && hasEventPositions(supportDetails)
      )
      .withFailureMessage(i18n.t(''));

    this.validateIf(x => x.eventDetails)
      .isNotNull()
      .fulfills(
        ({ requestedMachines }) =>
          (requestedMachines || []).length > 0 &&
          requestedMachines.every((i, idx) => i != null && new MachineRowHasRequiredFields(idx, this.addErrors).extendValidate(i).isValid())
      )
      .when(({ eventDetails }) => eventDetails?.isMachineRelated)
      .when(x => !x.supportDetails || !x.supportDetails?.isNewSupportPositionModel)
      .when(x => x.eventDetails && !guidIsNullOrEmpty(x.eventDetails?.patternId))
      .withFailureMessage(i18n.t('At least one machine row is invalid.'));

    this.validateIf(x => x.eventDetails)
      .isNotNull()
      .fulfills(
        ({ requestedMachines }) =>
          (requestedMachines || []).length > 0 &&
          requestedMachines.every(
            (i, idx) => i != null && new NoPatternMachineRowHasRequiredFields(idx, this.addErrors).extendValidate(i).isValid()
          )
      )
      .when(({ eventDetails }) => eventDetails?.isMachineRelated)
      .when(x => !x.supportDetails || !x.supportDetails?.isNewSupportPositionModel)
      .when(x => x.eventDetails && guidIsNullOrEmpty(x.eventDetails?.patternId))
      .withFailureMessage(i18n.t('At least one machine row is invalid.'));
  }
}

export class ChangeUnblockButtonsValidator extends ExtendedAbstractValidator<EventUnblockButtonsDto> {
  constructor(onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Event Id is required'));
  }
}

export class MachineRowHasRequiredFields extends ExtendedAbstractValidator<RequestMachinesDto> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`MachineRow at position ${idx + 1}`);
    this.validateIfString(x => x && x.machineRelatedClusterId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x && x.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Equipment Type is required')}`);
  }
}

export class NoPatternMachineRowHasRequiredFields extends ExtendedAbstractValidator<RequestMachinesDto> {
  constructor(idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);
    const preffix = i18n.t(`MachineRow at position ${idx + 1}`);
    this.validateIfString(x => x && x.machineRelatedClusterId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Cluster is required')}`);

    this.validateIfString(x => x && x.equipmentTypeId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Equipment Type is required')}`);

    this.validateIfString(x => x && x.oemId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('OEM is required')}`);

    this.validateIfString(x => x && x.machineModelId)
      .isNotEmpty()
      .withFailureMessage(`${preffix}: ${i18n.t('Machine Model is required')}`);
  }
}

export class ChangeEventInstructorValidator extends ExtendedAbstractValidator<ChangeEventInstructorDto> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`Instructor at position ${idx + 1}`);
    this.validateIf(x => x.instructorId)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid instructor selected')}`);

    this.validateIf(x => x.role)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Role is required')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period From Date is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period To Date is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Location is required')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.from).isSameOrBefore(DateTimeService.toMoment(x.to), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Invalid Period')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.from).isSameOrAfter(DateTimeService.toMoment(dateFrom), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period Date From is not in the Event range')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.to).isSameOrBefore(DateTimeService.toMoment(dateTo), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period Date To is not in the Event range')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { departure }, period: { from, to }, noTravel }) => {
        if ((departure?.from == null || departure?.to == null) && !noTravel) return false;
        const fromDep = DateTimeService.toMoment(departure.from);
        const toDep = DateTimeService.toMoment(departure.to);
        const isFromBtw = fromDep.isBetween(from, to, 'day', '()');
        const isToBtw = toDep.isBetween(from, to, 'day', '()');
        return !isFromBtw && !isToBtw && toDep.isSameOrBefore(from);
      })
      .when(({ travelDays }) => travelDays?.departure?.from != null || travelDays?.departure?.to != null)
      .withFailureMessage(
        `${preffix}: ${i18n.t(
          `Invalid Departure range. Both start date and end date of Departure should be out of the instructor's period range.`
        )}`
      );

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { arrival }, period: { from, to }, noTravel }) => {
        if ((arrival?.from == null || arrival?.to == null) && !noTravel) return false;
        const fromArrival = DateTimeService.toMoment(arrival.from);
        const toArrival = DateTimeService.toMoment(arrival.to);
        const isFromBtw = fromArrival.isBetween(from, to, 'day', '()');
        const isToBtw = toArrival.isBetween(from, to, 'day', '()');
        return !isFromBtw && !isToBtw && fromArrival.isSameOrAfter(from);
      })
      .when(({ travelDays }) => travelDays?.arrival?.from != null || travelDays?.arrival?.to != null)
      .withFailureMessage(
        `${preffix}: ${i18n.t(
          `Invalid Arrival range. Both start date and end date of Arrival should be out of the instructor's period range.`
        )}`
      );

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { arrival, departure }, noTravel }) => {
        const fromDep = DateTimeService.toMoment(departure.from);
        const toDep = DateTimeService.toMoment(departure.to);
        const fromBefore = fromDep.isBefore(arrival.to) && fromDep.isBefore(arrival.from);
        const toBefore = toDep.isBefore(arrival.to) && toDep.isBefore(arrival.from);
        return noTravel || (fromBefore && toBefore);
      })
      .when(
        ({ travelDays }) =>
          travelDays?.arrival?.from && travelDays?.arrival?.to && travelDays?.departure?.from && travelDays?.departure?.to != null
      )
      .withFailureMessage(`${preffix}: ${i18n.t(`Invalid travel days range`)}`);
  }
}

export class ChangeEventWithSupportDetailsInstructorValidator extends ExtendedAbstractValidator<ChangeEventInstructorDto> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`Instructor at position ${idx + 1}`);
    this.validateIf(x => x.instructorId)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid instructor selected')}`);

    this.validateIf(x => x.role)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Role is required')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period From Date is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period To Date is required')}`);

    this.validateIf(x => x.period.from)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Location is required')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.from).isSameOrBefore(DateTimeService.toMoment(x.to), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Invalid Period')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.from).isSameOrAfter(DateTimeService.toMoment(dateFrom), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period Date From is not in the Event range')}`);

    this.validateIf(x => x.period)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.to).isSameOrBefore(DateTimeService.toMoment(dateTo), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Instructor Period Date To is not in the Event range')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { departure }, period: { from, to }, noTravel }) => {
        if ((departure?.from == null || departure?.to == null) && !noTravel) return false;
        const fromDep = DateTimeService.toMoment(departure.from);
        const toDep = DateTimeService.toMoment(departure.to);
        const isFromBtw = fromDep.isBetween(from, to, 'day', '()');
        const isToBtw = toDep.isBetween(from, to, 'day', '()');
        return !isFromBtw && !isToBtw && toDep.isSameOrBefore(from);
      })
      .when(({ travelDays }) => travelDays?.departure?.from != null || travelDays?.departure?.to != null)
      .withFailureMessage(
        `${preffix}: ${i18n.t(
          `Invalid Departure range. Both start date and end date of Departure should be out of the instructor's period range.`
        )}`
      );

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { arrival }, period: { from, to }, noTravel }) => {
        if ((arrival?.from == null || arrival?.to == null) && !noTravel) return false;
        const fromArrival = DateTimeService.toMoment(arrival.from);
        const toArrival = DateTimeService.toMoment(arrival.to);
        const isFromBtw = fromArrival.isBetween(from, to, 'day', '()');
        const isToBtw = toArrival.isBetween(from, to, 'day', '()');
        return !isFromBtw && !isToBtw && fromArrival.isSameOrAfter(from);
      })
      .when(({ travelDays }) => travelDays?.arrival?.from != null || travelDays?.arrival?.to != null)
      .withFailureMessage(
        `${preffix}: ${i18n.t(
          `Invalid Arrival range. Both start date and end date of Arrival should be out of the instructor's period range.`
        )}`
      );

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { arrival, departure }, noTravel }) => {
        const fromDep = DateTimeService.toMoment(departure.from);
        const toDep = DateTimeService.toMoment(departure.to);
        const fromBefore = fromDep.isBefore(arrival.to) && fromDep.isBefore(arrival.from);
        const toBefore = toDep.isBefore(arrival.to) && toDep.isBefore(arrival.from);
        return noTravel || (fromBefore && toBefore);
      })
      .when(
        ({ travelDays }) =>
          travelDays?.arrival?.from && travelDays?.arrival?.to && travelDays?.departure?.from && travelDays?.departure?.to != null
      )
      .withFailureMessage(`${preffix}: ${i18n.t(`Invalid travel days range`)}`);
  }
}

export class ChangeNewSupportPositionValidator extends ExtendedAbstractValidator<ChangeNewEventSupportPositionsDto> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`Support position at position ${idx + 1}`);
    this.validateIf(x => x.id)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid support position')}`);

    this.validateIf(x => x)
      .isNotNull()
      .fulfills(({ eventNewModelPositions }) =>
        eventNewModelPositions.every(
          (i, idx) =>
            i != null && new ChangeEventNewModelPositionsValidator(dateFrom, dateTo, idx, this.addErrors).extendValidate(i).isValid()
        )
      )
      .when(({ eventNewModelPositions }) => (eventNewModelPositions || []).length > 0)
      .withFailureMessage(i18n.t(''));
  }
}

export class ChangeEventNewModelPositionsValidator extends ExtendedAbstractValidator<ChangeEventPositionDto> {
  constructor(dateFrom: string, dateTo: string, idx: number, onErrors?: (...failures: ValidationFailure[]) => void) {
    super(onErrors);

    const preffix = i18n.t(`Event position at position ${idx + 1}`);
    this.validateIf(x => x.userId)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('There is no valid user selected')}`);

    this.validateIf(x => x.startDate)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Event postion start date is required')}`);

    this.validateIf(x => x.endDate)
      .isNotNull()
      .isDefined()
      .withFailureMessage(`${preffix}: ${i18n.t('Event position end date is required')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.startDate).isSameOrBefore(DateTimeService.toMoment(x.endDate), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Invalid event position start date - end date period')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.startDate).isSameOrAfter(DateTimeService.toMoment(dateFrom), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Event position start date is not in the Event range')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(x => DateTimeService.toMoment(x.endDate).isSameOrBefore(DateTimeService.toMoment(dateTo), 'day'))
      .withFailureMessage(`${preffix}: ${i18n.t('Event position end date is not in the Event range')}`);

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { departure }, startDate, endDate, noTravel }) => {
        if ((departure?.from == null || departure?.to == null) && !noTravel) return false;
        const fromDep = DateTimeService.toMoment(departure.from);
        const toDep = DateTimeService.toMoment(departure.to);
        const isFromBtw = fromDep.isBetween(startDate, endDate, 'day', '()');
        const isToBtw = toDep.isBetween(startDate, endDate, 'day', '()');
        return !isFromBtw && !isToBtw && toDep.isSameOrBefore(startDate);
      })
      .when(({ travelDays }) => travelDays?.departure?.from != null || travelDays?.departure?.to != null)
      .withFailureMessage(
        `${preffix}: ${i18n.t(
          `Invalid Event position Departure range. Both start date and end date of Departure should be out of the event position period range.`
        )}`
      );

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { arrival }, startDate, endDate, noTravel }) => {
        if ((arrival?.from == null || arrival?.to == null) && !noTravel) return false;
        const fromArrival = DateTimeService.toMoment(arrival.from);
        const toArrival = DateTimeService.toMoment(arrival.to);
        const isFromBtw = fromArrival.isBetween(startDate, endDate, 'day', '()');
        const isToBtw = toArrival.isBetween(startDate, endDate, 'day', '()');
        return !isFromBtw && !isToBtw && fromArrival.isSameOrAfter(startDate);
      })
      .when(({ travelDays }) => travelDays?.arrival?.from != null || travelDays?.arrival?.to != null)
      .withFailureMessage(
        `${preffix}: ${i18n.t(
          `Invalid Event Position Arrival range. Both start date and end date of Arrival should be out of the event position period range.`
        )}`
      );

    this.validateIf(x => x)
      .isNotNull()
      .isDefined()
      .fulfills(({ travelDays: { arrival, departure }, noTravel }) => {
        const fromDep = DateTimeService.toMoment(departure.from);
        const toDep = DateTimeService.toMoment(departure.to);
        const fromBefore = fromDep.isBefore(arrival.to) && fromDep.isBefore(arrival.from);
        const toBefore = toDep.isBefore(arrival.to) && toDep.isBefore(arrival.from);
        return noTravel || (fromBefore && toBefore);
      })
      .when(
        ({ travelDays }) =>
          travelDays?.arrival?.from && travelDays?.arrival?.to && travelDays?.departure?.from && travelDays?.departure?.to != null
      )
      .withFailureMessage(`${preffix}: ${i18n.t(`Invalid travel days range`)}`);
  }
}

@repository('@@EVENTS', 'events.summary')
export class EventsStore extends DataStore<EventDto> {
  EVENTS_DOWNLOADED = 'EVENTS_DOWNLOADED';
  //baseUrl = 'http://localhost:7071/api';
  baseUrl = `events`;
  createPath = 'v1/create-event';
  retrievePath = 'v3/get-events';
  updatePath = 'v1/update-event';
  deletePath = '';
  retrieveOnePath = 'v1/get-event';
  retriveRoleIsCreatorPath = 'v1/get-if-creator-role';
  retrieveEventsByEmployee = 'v1/get-events-with-students';
  downloadUrl = 'v1/export-events';

  protected validate(item: EventDto) {
    return new ChangeEventValidator().extendValidate(item as any);
  }

  constructor() {
    super('EVENT', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });

    this.downloadAll.bind(this);
    this.onDownloadAll.bind(this);
    this.addReducer(this.EVENTS_DOWNLOADED, this.onDownloadAll, 'AsyncAction');
  }

  public async downloadAll(queryString?: string) {
    const params = queryString || '';
    const url = `${this.baseUrl}/${this.downloadUrl}${params}`;
    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.EVENTS_DOWNLOADED, httpService.download(url, `Events.xlsx`));
  }

  private onDownloadAll = () => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: () => ({ ...this.state, isBusy: false, result: undefined }),
      onError: error => ({
        ...this.state,
        isBusy: false,
        result: error && error.response && error.response.data && error.response.data.messages ? error.response.data : error
      })
    };
  };

  public async getById(id: string): Promise<EventDto> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<EventDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`);
    return result.data;
  }

  public async getIfRoleIsCreator(role: string): Promise<boolean> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<boolean>(`${this.baseUrl}/${this.retriveRoleIsCreatorPath}/${role}`);
    return result.data;
  }

  public async getAllAsync(query: Query, data?: any): Promise<QueryResult<EventDto>> {
    const httpService = container.get(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<EventDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
    );

    return result.data;
  }

  public async getAllAsyncByEmployeeId(query: Query, userId?: string): Promise<QueryResult<EventDto>> {
    const httpService = container.get(HttpService);

    query.toBody = true;
    const { path, body } = DataStore.getRequestParts(query);

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.post<any, QueryResult<EventDto>>(`${this.baseUrl}/${this.retrieveEventsByEmployee}?${path}`, {
        ...body,
        studentsUserId: userId ? [userId] : null
      })
    );

    return result.data;
  }
}

@repository('@@EVENTS', 'events.change')
export class ChangeEventsStore extends FormStore<ChangeEventDto> {
  //baseUrl = 'http://localhost:7071/api';
  baseUrl = `events`;
  createPath = 'v1/create-event';
  retrievePath = 'v2/get-events';
  updatePath = 'v1/update-event';
  nextStatusPath = 'v1/next-event-status';
  previousStatusPath = 'v1/previous-event-status';
  validateStatusPath = 'v1/validate-status';
  closeEventPath = 'v1/close-event';
  cancelEventPath = 'v1/cancel-event';
  retrieveOnePath = 'v1/get-event';

  protected validate(item: ChangeEventDto) {
    return new ChangeEventValidator().extendValidate(item);
  }

  constructor() {
    super('CHANGE_EVENT', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }

  async mergeRequestToEvent(requestId: string, eventId: string) {
    let httpService = container.get<HttpService>(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.post<void, boolean>(`events/v1/merge-request-event/${requestId}/${eventId}`, null)
    );
    return result.data;
  }

  changeEventInstructorDto(instructors: ChangeEventInstructorDto[]) {
    const { item } = this.state;
    const change = {};
    change[nameof<ChangeEventDto>('instructors')] = instructors;
    this.change({ ...item, ...change });
  }

  removeTrainingMaterial(trainingMaterial: TrainingMaterialDto, index: number): TrainingMaterialDto[] {
    const { item } = this.state;

    let trainingMaterials = item.trainingMaterials;
    trainingMaterials.splice(index, 1);

    const change = {};
    change[nameof<ChangeEventDto>('trainingMaterials')] = trainingMaterials;
    this.change({ ...item, ...change });

    return trainingMaterials;
  }

  addTrainingMaterial(trainingMaterial: TrainingMaterialDto): TrainingMaterialDto[] {
    const { item } = this.state;

    let changeTrainingMaterial: ChangeTrainingMaterialDto = {
      title: trainingMaterial.title,
      link: trainingMaterial.link
    };

    let trainingMaterials = [...(item.trainingMaterials || []), changeTrainingMaterial];

    const change = {};
    change[nameof<ChangeEventDto>('trainingMaterials')] = trainingMaterials;
    this.change({ ...item, ...change });
    return trainingMaterials;
  }
}

@repository('@@EVENTS', 'events.unblock-buttons')
export class ChangeUnblockButtonsStore extends DataStore<EventUnblockButtonsDto> {
  //baseUrl = 'http://localhost:7071/api';
  baseUrl = `events`;
  createPath = '';
  retrievePath = '';
  updatePath = '';
  retrieveOnePath = '';
  deletePath = '';
  updateUnblockButtonsPath = 'v1/update-blocked-foreign-resources-event';

  protected validate(item: EventUnblockButtonsDto): ValidationResult {
    throw new ChangeUnblockButtonsValidator().extendValidate(item);
  }

  constructor() {
    super('EVENT_UNBLOCKBUTTONS', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  async updateUnlockButtons(dto: EventUnblockButtonsDto) {
    const httpService = container.get(HttpService);
    const result = await httpService.put(`${this.baseUrl}/${this.updateUnblockButtonsPath}`, dto);
    return result.data;
  }
}

@repository('@@EVENTS', 'events.scheduler-summary')
export class EventsSchedulerStore extends DataStore<EventDto> {
  //baseUrl = 'http://localhost:7071/api';
  baseUrl = `events`;
  createPath = 'v1/create-event';
  retrievePath = 'v3/get-events';
  updatePath = 'v1/update-event';
  deletePath = '';
  retrieveOnePath = 'v1/get-event';
  retriveRoleIsCreatorPath = 'v1/get-if-creator-role';

  protected validate(item: EventDto) {
    return new ChangeEventValidator().extendValidate(item as any);
  }

  constructor() {
    super('EVENT_SCHEDULER', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getById(id: string): Promise<EventDto> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<EventDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`);
    return result.data;
  }

  public async getIfRoleIsCreator(role: string): Promise<boolean> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<boolean>(`${this.baseUrl}/${this.retriveRoleIsCreatorPath}/${role}`);
    return result.data;
  }

  public async getAllFromSchedulerAsync(query: Query, data?: any): Promise<QueryResult<EventDto>> {
    const httpService = container.get(HttpService);

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,
      httpService.get<QueryResult<EventDto>>(
        `${this.baseUrl}/${this.retrievePath}?requestedFromScheduler=true&${DataStore.buildUrl(query)}`,
        data
      )
    );

    return result.data;
  }
}

@repository('@@EVENTS', 'events.instructor-tab-summary')
export class InstructorTabEventsStore extends DataStore<EventDto> {
  EVENTS_DOWNLOADED = 'EVENTS_DOWNLOADED';
  //baseUrl = 'http://localhost:7071/api';
  baseUrl = `events`;
  createPath = '';
  retrievePath = 'v3/get-events';
  updatePath = '';
  deletePath = '';
  retrieveOnePath = '';
  lastQuery?: object = null;

  protected validate(item: EventDto) {
    return new ChangeEventValidator().extendValidate(item as any);
  }

  constructor() {
    super('EVENTS_INSTRUCTOR_TAB', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getAllAsync(query: Query, data?: any): Promise<QueryResult<EventDto>> {
    if (this.lastQuery && query && JSON.stringify(this.lastQuery) === JSON.stringify(query)) {
      let eventsResult = { items: this.state.items.map(({ item }) => item), count: this.state.items.length } as QueryResult<EventDto>;
      return new Promise<QueryResult<EventDto>>(resolve => resolve(eventsResult));
    } else {
      const httpService = container.get(HttpService);
      const { path, body } = DataStore.getRequestParts(query);

      if (body != null) {
        data = data || {};
        data = { ...data, ...body };
      }

      const result = await this.dispatchAsync(
        this.ENTITY_LIST_UPDATE,
        httpService.get<QueryResult<EventDto>>(`${this.baseUrl}/${this.retrievePath}?${path}`, data)
      );
      this.lastQuery = query;
      return result.data;
    }
  }
}
