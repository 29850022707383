import * as autobind from 'autobind';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { resolve } from 'inversify.config';
import { Icon, Message, Form, Button, Grid, Dropdown, Loader, Dimmer, Modal, Container } from 'semantic-ui-react';
import { RequestDto, RequestsStore, RequestStatus } from '../../../stores/requests/requests-store';
import { ItemState, OrderDefinition, Query, ItemReference } from '../../../stores/dataStore';
import { CommandResult } from '../../../stores/types';
import { nameof, getProperties } from '../../../utils/object';
import NewRequestView from './request-wizard';
import { TableModel, TableView, TablePagination } from '../../../widgets/collections/table';
import { CountryFlag } from 'widgets/bussiness/country-flag';
import LineSeparator from 'widgets/bussiness/line-separator';
import { extractFriendlyIdNumber, isNullOrWhiteSpaces } from 'utils/useful-functions';
import DeliveryMethodEditor from 'widgets/bussiness/delivery-method-editor';
import TrainingLevelEditor from 'widgets/bussiness/training-level-editor';
import { DateInput } from 'widgets/form/dateInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPencilRuler, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import './request-list.less';
import { EventDto, EventsStore } from 'stores/events/events-store';
import EventForm from '../events/event-form/event-form';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { IdentityService } from 'services/identity-service';
import { decamelCase } from '../../../utils/event-utils';
import { DateTimeService } from 'services/datetime-service';
import TableTooltipCell from 'widgets/collections/table-tooltip-cell';
import RequestForm, { RequestFormActions } from './request-form/request-form';
import { buildRequestFormViewModel } from 'stores/requests/request-form-store';
import { buildEventViewModel } from 'stores/events/event-form-store';
import { EventFormActions } from '../events/event-form/buttons/action-buttons';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import MultiEventTypeEditor from 'widgets/bussiness/multiple-selector/multi-event-type-editor';
import MultipleProfessionsEditor from 'widgets/bussiness/multiple-selector/multiple-professions-editor';
import { ProfessionDto } from 'stores/configuration/profiles/profession-roles-store';
import MultipleLocationEditor from 'widgets/bussiness/multiple-selector/multiple-location-editor';
import { LocationDto } from 'stores/configuration/locations/locations-store';
import CategoryFormEditor from 'widgets/bussiness/category-form-editor';
import { Category } from 'stores/configuration/events-n-requests/non-machine-related/clusters-store';
import { PriorityDto } from 'stores/configuration/events-n-requests/priorities-store';
import MultiplePriorityEditor from 'widgets/bussiness/multiple-selector/multiple-priority-editor';
import MultipleRequestStatusEditor from 'widgets/bussiness/multiple-selector/multiple-request-status-editor';
import MultipleMachineModelEditor from 'widgets/bussiness/multiple-selector/multiple-machine-model-editor';
import { MachineModelDto } from 'stores/configuration/machinery/machine-models-store';

export interface RequestsListProps extends RouteComponentProps, WithTranslation {
  requests: RequestsStore;
  eventsStore: EventsStore;
}

export interface RequestsListState {
  query: Query;
  newRequestShown: boolean;
  changeRequestShown: boolean;
  modalRequestForm: boolean;
  modalEventForm: boolean;
  activeFilters: { [key: string]: any };
  selectedItem: any;
  showFilters: boolean;
  eventType: ItemReference;
  location: ItemReference;
  deliveryMethod: ItemReference;
  trainingLevel: ItemReference;
  clearSelection: boolean;
  mergeInAction: boolean;
  eventItemParent: any;
  mergeError: boolean;
  mergeErrorMessage: string[];
  mergeShown: boolean;
  mergeEvent: EventDto;
  openedEventForm: EventDto;
  parentId: string;
  onMerging: boolean;
  onLoadEvent: boolean;
  mergeEventShown: boolean;
  startDate: string;
  endDate: string;
  active: boolean;
  requestMergeIds: string[];
  mainRequest: RequestDto;
  showNewRequestButton: boolean;
  exportElements: boolean;
  pagination: TablePagination;
  togglePagination: boolean;
  eventTypeList: ItemReference[];
  rolesList: ProfessionDto[];
  requestingLocationList: LocationDto[];
  category: Category;
  priorityList: PriorityDto[];
  requestStatus: RequestStatus[];
  machinesModel: MachineModelDto[];
  loading: boolean;
  someFilterOpened: boolean;
  cloningError: boolean;
  cloningErrorMessage: string[];
}

@connect(['requests', RequestsStore], ['eventsStore', EventsStore])
class RequestsListPage extends React.Component<RequestsListProps, RequestsListState> {
  @resolve(IdentityService)
  private identityService: IdentityService;

  timer: any = null;
  currentUserPlannerRole: boolean = false;
  plannerCustomPageSize: any[] = [
    { text: '50 / page', value: 50 },
    { text: '100 / page', value: 100 },
    { text: '150 / page', value: 150 },
    { text: '200 / page', value: 200 }
  ];
  defaultPageSize: any[] = [
    { text: '10 / page', value: 10 },
    { text: '20 / page', value: 20 },
    { text: '30 / page', value: 30 },
    { text: '50 / page', value: 50 }
  ];

  constructor(props) {
    super(props);

    let pagination: TablePagination = null;
    let take = 10;
    const currentUserInfo = this.identityService.getUserInfo();

    if (IdentityService.isPlanner(currentUserInfo)) {
      take = this.plannerCustomPageSize[0].value;
      this.currentUserPlannerRole = true;
      pagination = {
        activePage: 1,
        options: this.plannerCustomPageSize,
        pageSize: this.plannerCustomPageSize[0].value
      };
    } else if (IdentityService.isRegionalManufacturingVP(currentUserInfo) || IdentityService.isGlobalManufacturing(currentUserInfo)) {
      pagination = {
        activePage: 1,
        options: this.defaultPageSize,
        pageSize: this.defaultPageSize[3].value
      };
      take = this.defaultPageSize[3].value;
    }

    this.state = {
      query: { searchQuery: '', orderBy: [{ direction: 'Descending', field: 'FriendlyEventId', useProfile: false }], skip: 0, take: take },
      newRequestShown: false,
      changeRequestShown: false,
      modalRequestForm: false,
      modalEventForm: false,
      openedEventForm: null,
      activeFilters: {
        isCanceled: [{ isCanceled: false }],
        status: this.getFilterStatus()
      },
      selectedItem: null,
      showFilters: false,
      eventType: null,
      location: null,
      deliveryMethod: null,
      trainingLevel: null,
      startDate: null,
      endDate: null,
      active: true,
      clearSelection: false,
      mergeInAction: false,
      eventItemParent: null,
      mergeErrorMessage: [],
      mergeError: false,
      mergeShown: false,
      mergeEvent: null,
      onMerging: null,
      onLoadEvent: null,
      parentId: null,
      mergeEventShown: false,
      requestMergeIds: [],
      mainRequest: null,
      showNewRequestButton: false,
      exportElements: false,
      togglePagination: false,
      eventTypeList: [],
      rolesList: [],
      requestingLocationList: [],
      category: null,
      priorityList: [],
      requestStatus: [],
      machinesModel: [],
      pagination: pagination,
      loading: false,
      someFilterOpened: false,
      cloningErrorMessage: [],
      cloningError: false
    };
  }

  @autobind
  private handlePageChange(skip: number, take: number) {
    let pagination = this.currentUserPlannerRole
      ? { activePage: skip / take + 1, pageSize: take, options: this.plannerCustomPageSize }
      : null;

    this.setState(({ query }) => ({ togglePagination: true, query: { ...query, skip, take }, pagination }), this.load);
  }

  @autobind
  private getFilterStatus(): any {
    if (this.currentUserPlannerRole) return { or: [{ Status: 'Pending' }] };
    else return { or: [{ Status: 'Draft' }, { Status: 'Pending' }, { Status: 'InProgress' }] };
  }

  @autobind
  private async onClickColRequestId(item: RequestDto) {
    this.setState({ loading: true });
    let elem = await this.requestStore.getById(item.id);
    this.setState({ selectedItem: elem, modalRequestForm: true, loading: false });
  }

  @autobind
  private onClickColEventId(eventId: string) {
    this.setState({ onLoadEvent: true });
    this.props.eventsStore
      .getById(eventId)
      .then(event => this.setState({ openedEventForm: event, modalEventForm: true, onLoadEvent: false }));
  }

  @autobind
  private async onSaveRow(item: RequestDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') await this.props.requests.saveAsync(item, state);

    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private async onDelete(item: RequestDto, state: ItemState): Promise<CommandResult<any>> {
    if (state !== 'New') await this.props.requests.deleteAsync(item.id, state);

    return { isSuccess: true, items: [], messages: [] };
  }

  @autobind
  private handleFilterChange(filters: { id: string; filter: any }[]) {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, () => this.load());
  }

  @autobind
  private onNewItem() {
    this.setState({ newRequestShown: true });
  }

  @autobind
  private onNewItemClosed() {
    this.setState({ newRequestShown: false });
    this.load();
  }

  @autobind
  private onRequestFormClosed(request?: RequestDto, actionPerformed?: RequestFormActions) {
    this.setState({ modalRequestForm: false });
    if (!(actionPerformed && actionPerformed === 'close')) {
      this.load();
    }
  }

  @autobind
  private onEventFormClosed() {
    this.setState({ modalEventForm: false, openedEventForm: null });
    this.load();
  }

  @autobind
  private async onRequest() {
    const { selectedItem } = this.state;
    this.setState({ loading: true });
    let elem = await this.requestStore.getById(selectedItem.id);
    this.setState({ modalRequestForm: true, selectedItem: elem, loading: false });
  }

  private get requestStore() {
    return this.props.requests;
  }

  private toggleActive = () => {
    let active = !this.state.active;
    const activeFilters = { ...this.state.activeFilters };
    let showFilters = this.state.showFilters;

    showFilters = showFilters ? !showFilters : showFilters;

    if (!active) activeFilters['status'] = { or: [{ Status: 'Accepted' }, { Status: 'Rejected' }] };
    else activeFilters['status'] = this.getFilterStatus();

    this.setState({ active, activeFilters, requestStatus: [], showFilters }, this.load);
  };

  private onNewItemOpen = () => {
    this.setState(({ newRequestShown }) => ({ newRequestShown: !newRequestShown }));
  };

  private toggleFilters = () => {
    const { showFilters, active } = this.state;
    const activeFilters = {
      isCanceled: [{ isCanceled: false }],
      status: { or: [{ Status: 'Accepted' }, { Status: 'Rejected' }] }
    };

    if (active) activeFilters['status'] = this.getFilterStatus();
    else activeFilters['status'] = { or: [{ Status: 'Accepted' }, { Status: 'Rejected' }] };

    this.setState(
      {
        activeFilters,
        showFilters: !showFilters,
        eventType: null,
        location: null,
        deliveryMethod: null,
        trainingLevel: null,
        startDate: null,
        endDate: null,
        requestStatus: []
      },
      () => {
        if (!this.state.showFilters) this.load();
      }
    );
  };

  private onRequestingLocationListChange = (fieldName: string, value: LocationDto[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, requestingLocationList: [] }, this.load);
    } else {
      activeFilters['requests'] = {
        requestingLocationId: { in: { type: 'guid', value: value.map(location => location.id) } }
      };
    }
    this.setState({ ...this.state, activeFilters, requestingLocationList: value }, this.load);
  };

  private onPriorityListFilterChange = (fieldName: string, value: PriorityDto[]) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, priorityList: [] }, this.load);
    } else {
      const filterValue = {};
      filterValue['priorityId'] = { in: { value: value.map(priority => priority.id), type: 'guid' } };

      activeFilters[fieldName] = filterValue;
    }
    this.setState({ ...this.state, activeFilters, priorityList: value }, this.load);
  };

  private onStatusFilterChange = (fieldName: string, value: RequestStatus[]) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value || value.length <= 0) {
      if (!this.state.active) {
        activeFilters[fieldName] = { or: [{ Status: 'Accepted' }, { Status: 'Rejected' }] };
      } else {
        activeFilters[fieldName] = this.getFilterStatus();
      }

      this.setState({ ...this.state, requestStatus: [] }, this.load);
    } else {
      const filterValue = {};

      filterValue[fieldName] = { in: value };
      activeFilters[fieldName] = filterValue;
    }

    this.setState({ ...this.state, activeFilters, requestStatus: value }, this.load);
  };

  private onEventTypeFilterChanged = (value, fieldName) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
    } else {
      const filterValue = {};
      filterValue['EventType/OriginalEventTypeId'] = { in: { type: 'guid', value: value } };
      activeFilters[fieldName] = filterValue;
    }

    this.setState({ activeFilters }, this.load);
  };

  private onCategoryChange = (fieldName, value) => {
    this.setState({ category: value });
    const activeFilters = { ...this.state.activeFilters };

    if (!value) {
      delete activeFilters[fieldName];
      this.setState({ category: null });
    } else {
      activeFilters['category'] = { category: { eq: value } };
    }

    this.setState({ activeFilters }, this.load);
  };

  private onMachineModelFilterChanged(fieldName: string, value: MachineModelDto[]) {
    const activeFilters = { ...this.state.activeFilters };
    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, machinesModel: [] }, this.load);
    } else {
      activeFilters[fieldName] = {
        requestMachines: { any: [{ machineModelId: { in: { type: 'guid', value: value.map(machine => machine.id) } } }] }
      };
    }

    this.setState({ ...this.state, activeFilters, machinesModel: value }, this.load);
  }

  private onDropDownFilterAnotherMSChanged(value, fieldName) {
    const activeFilters = { ...this.state.activeFilters };
    const keys = (value as string[]) || [];

    if (keys.length === 0) delete activeFilters[fieldName];
    else activeFilters[fieldName] = { [fieldName]: { eq: { type: 'guid', value: value.id } } };

    this.setState({ activeFilters }, this.load);
  }

  private onRequestIDFilterChanged = (e, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['friendlyId'];
      this.setState({ activeFilters }, this.load);
    } else {
      const friendlyId = extractFriendlyIdNumber(value, 'R');

      if (!Number.isNaN(friendlyId)) {
        activeFilters['friendlyId'] = `contains(cast(FriendlyEventId, 'Edm.String'), '${friendlyId}')`;
      }

      this.setState({ activeFilters }, this.load);
    }
  };

  private onRolesListChange = (fieldName: string, value: ProfessionDto[]) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value || value.length <= 0) {
      delete activeFilters[fieldName];
      this.setState({ ...this.state, rolesList: [] }, this.load);
    } else {
      const filterValue = {};
      filterValue['RoleId'] = { in: { value: value.map(roles => roles.id), type: 'guid' } };

      activeFilters[fieldName] = filterValue;
    }

    this.setState({ ...this.state, activeFilters, rolesList: value }, this.load);
  };

  private onRequestTitleFilterChanged = (e, { value }) => {
    const activeFilters = { ...this.state.activeFilters };
    if (isNullOrWhiteSpaces(value)) delete activeFilters['title'];
    else activeFilters['title'] = { 'tolower(title)': { contains: value.toLowerCase() } };
    this.setState({ activeFilters }, this.load);
  };

  private onMachineRelatedChanged = (e, { value }) => {
    const activeFilters = { ...this.state.activeFilters };
    if (value === '') delete activeFilters['isMachineRelated'];
    else activeFilters['isMachineRelated'] = { isMachineRelated: { eq: value } };
    this.setState({ activeFilters }, this.load);
  };

  private startDateFilterChanged = (_, value: string) => {
    const activeFilters = { ...this.state.activeFilters };
    activeFilters['startDate'] = { startDate: { ge: new Date(value) } };
    this.setState({ activeFilters: activeFilters, startDate: DateTimeService.toString(value) }, this.load);
  };

  private endDateFilterChanged = (_, value: string) => {
    const activeFilters = { ...this.state.activeFilters };
    if (!value) {
      delete activeFilters['endDate'];
      this.setState({ activeFilters, endDate: null }, this.load);
    } else {
      activeFilters['endDate'] = { endDate: { le: new Date(value) } };
      this.setState({ activeFilters, endDate: DateTimeService.toString(value) }, this.load);
    }
  };

  private async beginMerge(item: RequestDto, selectedRows: RequestDto[]) {
    this.setState({ onMerging: true });
    const selectedRowsItems = selectedRows;
    const ids = [];

    // check can do the merge
    for (let i = 0; i < selectedRowsItems.length; i++) {
      const currentItem = selectedRowsItems[i];
      ids.push(currentItem.id);
      if (currentItem.status.toString() !== 'Pending') {
        this.setState({
          mergeError: true,
          onMerging: false,
          mergeErrorMessage: [this.props.t('Only requests in Pending status can be merged.')]
        });
        return;
      }
    }

    let httpService = container.get<HttpService>(HttpService);
    try {
      const result = await httpService.post('events/v1/merge-request/' + item.id, ids);
      if (!(result.data as any).isSuccess) {
        this.setState({
          mergeError: true,
          onMerging: false,
          mergeErrorMessage: ((result.data as any).messages as any[]).map(x => this.props.t(x.body))
        });
        return;
      }
      let mergeEvent: EventDto = (result.data as any).item;
      mergeEvent.requests = selectedRows;
      mergeEvent.parentRequestId = item.id;
      this.setState({ mergeEvent, mergeShown: true });
      this.setState({ onMerging: false });
    } catch (error) {
      console.log({ error });
      const mergeErrorMessage = (error?.response?.data?.messages || []).map(x => this.props.t(x.body));
      this.setState({ mergeError: true, onMerging: false, mergeErrorMessage });
    }
  }

  private handleOnEventFormClose = (actionPerformanced: EventFormActions = null, payload: any = null) => {
    if (actionPerformanced === 'merge' && payload) this.navigateToNewEvent(payload);
    if (actionPerformanced === 'merge' || actionPerformanced === 'cancel') this.onMergeClose();
  };

  private navigateToNewEvent = event => this.props.history.replace(`/events/${event?.id}`);

  private onMergeClose(isSuccess: boolean = true): void {
    this.setState({ mergeShown: false });
    if (isSuccess) {
      this.load();
      this.setState({ clearSelection: !this.state.clearSelection });
    }
  }

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const { togglePagination } = this.state;
    const {
      friendlyId,
      eventType,
      title,
      machinesModel,
      profession,
      TrainingLevelId,
      requests,
      DeliveryMethodId,
      startDate,
      endDate,
      category,
      isMachineRelated,
      priority,
      status
    } = activeFilters;

    const filter = [].concat(
      ...getProperties(activeFilters)
        .filter(({ value }) => !!value)
        .map(({ value }) => value)
    );

    let initPagination =
      !togglePagination &&
      (friendlyId ||
        eventType ||
        title ||
        machinesModel ||
        profession ||
        TrainingLevelId ||
        requests ||
        DeliveryMethodId ||
        startDate ||
        endDate ||
        category ||
        isMachineRelated ||
        priority ||
        status);

    this.setState(
      ({ query }) => {
        const returnObj = { query: { ...query, filter } } as RequestsListState;
        if (initPagination) {
          returnObj.query.skip = 0;
          returnObj.pagination = { ...returnObj.pagination, activePage: 1 };
        }
        returnObj.togglePagination = false;
        return returnObj;
      },
      () => this.requestStore.getAllRequestListAsync(this.state.query)
    );
  };

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  componentDidMount() {
    this.load();

    const currentUserInfo = this.identityService.getUserInfo();
    if (IdentityService.isAdmin(currentUserInfo)) {
      this.setState({ showNewRequestButton: true });
    } else if (!IdentityService.isAdmin(currentUserInfo) && !IdentityService.isWorker(currentUserInfo)) {
      this.props.requests.getIfRoleIsCreator(this.identityService.getUserInfo().activeRole).then(res => {
        this.setState({ showNewRequestButton: res });
      });
    }

    if (this.props.match.params['id'] != null) {
      const id = this.props.match.params['id'].toString();
      this.props.requests
        .getById(id)
        .then(e => {
          this.setState({ selectedItem: e }, this.onRequest);
        })
        .catch(_ => {
          this.props.history.replace('/not-found');
        });
    }
  }

  private onCloneItem = item => {
    this.setState({ loading: true });
    const id = item.id;
    this.props.requests
      .cloneById(id)
      .then(result => this.setState({ loading: false }, () => this.load()))
      .catch(error => {
        this.setState({
          loading: false,
          cloningError: true,
          cloningErrorMessage: (error?.response?.data?.messages || []).map(x => this.props.t(x.body))
        });
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params['id'] !== nextProps.match.params['id'] && nextProps.match.params['id'] !== undefined) {
      const id = nextProps.match.params['id'].toString();
      this.props.requests
        .getById(id)
        .then(e => {
          this.setState({ selectedItem: e }, this.onRequest);
        })
        .catch(_ => {
          this.props.history.replace('/not-found');
        });
    }
  }

  handleOnRowDoubleClick = item => {
    this.setState({ selectedItem: item }, () => this.onRequest());
  };

  handleOnEnterKeydown = item => {
    this.handleOnRowDoubleClick(item);
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  async exportElements() {
    this.setState({ exportElements: !this.state.exportElements });
  }

  activeRoleCanSelectRows(): boolean {
    let currentUserInfo = this.identityService.getUserInfo();

    return IdentityService.isPlanner(currentUserInfo);
  }

  activeRoleCanViewExtraActions(status: string): boolean {
    let currentUserInfo = this.identityService.getUserInfo();

    return !IdentityService.isRegionalManufacturingVP(currentUserInfo) || status !== 'Pending for VP';
  }

  public render() {
    const { t, history } = this.props as any;
    const { showFilters, pagination, someFilterOpened } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Request ID'),
          renderer: data => <a className="clickable-icon">{data.requestId}</a>,
          selectableHeader: true,
          sortDefinition: {
            field: 'friendlyEventId',
            useProfile: false,
            active: 'Descending'
          },
          onClick: data => this.onClickColRequestId(data)
        },
        {
          title: t('Event Type'),
          renderer: data => (
            <>
              <TableTooltipCell textToShow={data.eventType.name} />
            </>
          ),
          selectableHeader: true,
          sortDefinition: {
            field: 'eventType/name',
            useProfile: false
          }
        },
        {
          title: t('Request Title'),
          renderer: data => (
            <>
              <TableTooltipCell textToShow={data.title} />
            </>
          ),
          selectableHeader: true,
          sortDefinition: {
            field: nameof<RequestDto>('title'),
            useProfile: false
          }
        },
        {
          title: t('Machine Model'),
          renderer: ({ isMachineRelated, requestMachines, requestId }) =>
            isMachineRelated &&
            (requestMachines || []).length > 0 &&
            requestMachines.map(({ machineModelName }, idx) => (
              <div key={`${requestId}_DTTC_MachineModel_div_${machineModelName}_${idx}`}>
                <TableTooltipCell key={`${requestId}_DTTC_MachineModel_${machineModelName}`} textToShow={machineModelName} />
                <br />
              </div>
            )),
          selectableHeader: true
        },
        {
          title: t('Role'),
          renderer: data => (
            <>
              <TableTooltipCell textToShow={data.roleName} />
            </>
          ),
          selectableHeader: true
        },
        {
          title: t('Level'),
          renderer: data => (
            <>
              <TableTooltipCell textToShow={data.trainingLevelName} />
            </>
          ),
          selectableHeader: true,
          sortDefinition: {
            field: nameof<RequestDto>('trainingLevelName'),
            useProfile: false
          }
        },
        {
          title: t('Requesting Location'),
          tooltipRenderer: true,
          renderer: data => (
            <>
              <CountryFlag
                countryName={
                  data?.requestLocation?.code !== 'XXXX' && data?.requestLocation?.code !== 'XXXXX'
                    ? data.requestingLocationCountryName
                    : null
                }
              />
              &nbsp;{data.requestingLocationName}
            </>
          ),
          selectableHeader: true,
          sortDefinition: {
            field: nameof<RequestDto>('requestingLocationName'),
            useProfile: false
          }
        },
        {
          title: t('Delivery Method'),
          renderer: data => (
            <>
              <TableTooltipCell textToShow={data.deliveryMethodName} />
            </>
          ),
          selectableHeader: true,
          sortDefinition: {
            field: nameof<RequestDto>('deliveryMethodName'),
            useProfile: false
          }
        },
        {
          title: t('Desired Start Date'),
          renderer: data => <span>{DateTimeService.toDateInputString(data.startDate)}</span>,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<RequestDto>('startDate'),
            useProfile: false
          }
        },
        {
          title: t('Desired Duration'),
          tooltipRenderer: true,
          //renderer: data => <>{data?.plannedDuration + (data?.plannedDuration.includes('w/d') ? '' : ' w/d')}</>,
          renderer: data => (
            <>
              {Number.isNaN(Number.parseInt(data?.desiredEventDuration))
                ? data?.desiredEventDuration
                : Number.parseInt(data?.desiredEventDuration) + ' w/d'}
            </>
          ),
          selectableHeader: true
        },
        {
          title: t('Priority '),
          renderer: data => (
            <>
              <TableTooltipCell textToShow={data.priorityName} />
            </>
          ),
          selectableHeader: true,
          sortDefinition: {
            field: nameof<RequestDto>('priorityName'),
            useProfile: false
          }
        },
        {
          title: t('Students Requested'),
          tooltipRenderer: true,
          renderer: data => <span>{data.studentsNumber}</span>,
          selectableHeader: true
        },
        {
          title: t('Status'),
          renderer: data => <span>{data.statusDescription || decamelCase(data.status.toString())}</span>,
          selectableHeader: true,
          sortDefinition: {
            field: nameof<RequestDto>('statusDescription'),
            useProfile: false
          }
        },
        {
          title: t('Event ID'),
          renderer: data =>
            data.eventCreatedId ? (
              <a className="clickable-icon" onClick={() => this.onClickColEventId(data.eventCreatedId)}>
                {data.eventCreateFriendlyId}
              </a>
            ) : null,
          selectableHeader: true
        },
        {
          title: t('Request Submission Date'),
          renderer: data => <span>{DateTimeService.toDateInputString(data.submissionDate)}</span>,
          selectableHeader: true
        },

        {
          renderer: ({ customizationDataId }) => (customizationDataId ? <FontAwesomeIcon className="solid" icon={faPencilRuler} /> : null)
        },
        {
          renderer: ({ students }) => (students?.length > 0 ? <FontAwesomeIcon className="solid" icon={faUsers} /> : null)
        }
      ],
      data: this.props.requests.state,
      pagination: pagination
    } as TableModel<RequestDto>;

    return (
      <>
        <h3>{t('Requests')}</h3>
        <LineSeparator />
        <Dimmer active={this.state.onMerging} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Merging...')}</Loader>
        </Dimmer>
        <Dimmer active={this.state.onLoadEvent} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading event form')}</Loader>
        </Dimmer>
        <div className="event-wrapper">
          <Grid className="event-types-list-grid">
            {this.props.requests.state.result && !this.props.requests.state.result.isSuccess && (
              <Grid.Row className="event-types-list-error-row">
                <Message
                  icon="exclamation circle"
                  className="error-message__style"
                  error
                  header={t('An error ocurred')}
                  list={this.props.requests.state.result.messages?.map(o => o.body)}
                />
              </Grid.Row>
            )}
            <Grid.Row className="event-types-list-items-row request-list__table-view">
              <TableView
                /////////////////For build table keyboard navegation/////////////////
                selectable={!this.state.newRequestShown && !this.state.modalRequestForm && !this.state.mergeShown}
                //maxSelection={1}
                onHideCheckbox={!!!this.activeRoleCanSelectRows()}
                selectionType={'allRow'}
                onEnterKeydown={this.handleOnEnterKeydown}
                onRowDoubleClick={this.handleOnRowDoubleClick}
                preventEnterKeyDownEvent={someFilterOpened}
                //showActionsConfirmModal={true}
                /////////////////For build table keyboard navegation/////////////////
                model={tableModel}
                isHideExtraActions={item => !this.activeRoleCanViewExtraActions((item as RequestDto).statusDescription)}
                extraActions={[
                  {
                    content: (
                      <>
                        <Icon name="edit" />
                        {<span className="text__bold">{t('Edit')}</span>}
                      </>
                    ),
                    onClick: this.handleOnRowDoubleClick
                  },
                  {
                    content: (
                      <>
                        <Icon name="clone" />
                        {<span className="text__bold">{t('Clone')}</span>}
                      </>
                    ),
                    onClick: item => {
                      this.onCloneItem(item);
                    }
                  }
                ]}
                selectionActions={
                  this.activeRoleCanSelectRows() && [
                    {
                      content: (
                        <>
                          <FontAwesomeIcon className="solid float-right" icon={faToggleOn} />
                          &nbsp;{t('Merge')}
                        </>
                      ),
                      onClick: (item, selectedRows) => {
                        this.beginMerge(item as RequestDto, selectedRows);
                      }
                    }
                  ]
                }
                selectionActionMinElements={2}
                onOrderByChanged={this.handleOrderBy}
                onNewItem={this.onNewItem}
                onRefresh={this.load}
                canEdit={false}
                mainActionsFilters={
                  <Grid.Row className="event-types-list-filter-row event-home-list__filters-btns-wrapper" textAlign="right">
                    <Form>
                      <Form.Group className={'request-event__field-all-container-height-flexible'}>
                        <div className="request-event__filter-buttons">
                          <Form.Field>
                            <Button
                              type="button"
                              className="inverted-color-btn event-type-filter-icon-btn request-list__buttons"
                              onClick={this.toggleActive}
                              icon
                            >
                              <Icon size="large" name={!this.state.active ? 'file excel' : 'file alternate'} />
                            </Button>
                          </Form.Field>

                          {this.state.showNewRequestButton && (
                            <Form.Field className="new-request-list__btn" id="add-event-type">
                              <Button
                                type="button"
                                className="inverted-color-btn request-list__buttons request-list__new-request-btn"
                                onClick={this.onNewItemOpen}
                              >
                                {t('New Request')}&nbsp;
                                <Icon.Group>
                                  <Icon size="large" name="file alternate" />
                                  <Icon size="small" circular name="plus circle" />
                                </Icon.Group>
                              </Button>
                            </Form.Field>
                          )}
                          <Form.Field>
                            <Button
                              type="button"
                              className="inverted-color-btn event-type-filter-icon-btn request-list__buttons"
                              onClick={this.toggleFilters}
                              icon
                            >
                              <Icon.Group>
                                <Icon size="large" name="filter" />
                                {showFilters && <Icon corner className="hide-filter" name="remove" />}
                              </Icon.Group>
                            </Button>
                          </Form.Field>
                        </div>

                        {showFilters && (
                          <div className="request-list__filters-wrapper event-list__new-filters-container">
                            <Form.Field>
                              <ClearableTimerInput
                                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                className="filter margin-top-0 request__title-input"
                                icon="search"
                                placeholder={t('Request ID')}
                                onChange={this.onRequestIDFilterChanged}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultiEventTypeEditor
                                schedulerRender
                                placeholder={t('Event Type')}
                                useOriginalEventTypeIdAsValue
                                value={this.state.eventTypeList.map(eventTypeItem => eventTypeItem.id)}
                                onChange={value => this.onEventTypeFilterChanged(value, 'eventType')}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <ClearableTimerInput
                                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                                className="filter margin-top-0 request__title-input"
                                icon="search"
                                placeholder={t('Request Title')}
                                onChange={this.onRequestTitleFilterChanged}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <MultipleMachineModelEditor
                                placeholder={t('Machine Model')}
                                className="request-list__filter-dropdown"
                                value={this.state.machinesModel}
                                clearable
                                onChange={value => this.onMachineModelFilterChanged('machinesModel', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleProfessionsEditor
                                clearable
                                className=""
                                placeholder={t('Roles')}
                                value={this.state.rolesList}
                                onChange={value => this.onRolesListChange('profession', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <TrainingLevelEditor
                                placeholder={t('Level')}
                                className="request-list__filter-dropdown"
                                nullable
                                value={this.state.trainingLevel}
                                onChange={value => this.onDropDownFilterAnotherMSChanged(value, 'TrainingLevelId')}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleLocationEditor
                                clearable
                                placeholder={t('Requesting Location')}
                                value={this.state.requestingLocationList}
                                onChange={value => this.onRequestingLocationListChange('requests', value)}
                                showCountry={true}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <DeliveryMethodEditor
                                className="request-list__filter-dropdown"
                                placeholder={t('Delivery Method')}
                                nullable
                                value={this.state.deliveryMethod}
                                onChange={value => this.onDropDownFilterAnotherMSChanged(value, 'DeliveryMethodId')}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <DateInput
                                className="request-list__date-big-input "
                                value={this.state.startDate || null}
                                onChange={this.startDateFilterChanged}
                                placeholder={t('Desired Start Date')}
                                clearable
                                onClear={this.startDateFilterChanged}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <DateInput
                                placeholder={t('Desired End Date')}
                                className="request-list__date-big-input "
                                value={this.state.endDate || null}
                                onChange={this.endDateFilterChanged}
                                initialValue={this.state.startDate || null}
                                minDate={this.state.startDate || null}
                                clearable
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <CategoryFormEditor
                                readOnly={false}
                                className="filter margin-top-0 request__title-input"
                                value={this.state.category}
                                onChange={value => this.onCategoryChange('category', value)}
                                clearable
                                placeholder={t('Category')}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field>
                              <Dropdown
                                className="request-list__filter-dropdown"
                                placeholder={t('Machine Related')}
                                search
                                selection
                                clearable
                                options={[
                                  { key: 'machine', text: t('Yes'), value: true },
                                  {
                                    key: 'nonMachine',
                                    text: t('No'),
                                    value: false
                                  }
                                ]}
                                onChange={this.onMachineRelatedChanged}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultiplePriorityEditor
                                placeholder={t('Priority')}
                                clearable
                                value={this.state.priorityList}
                                onChange={value => this.onPriorityListFilterChange('priority', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>

                            <Form.Field className="events-home-page__multiple-dropdown-filter">
                              <MultipleRequestStatusEditor
                                clearable
                                statusFilterActive={this.state.active}
                                placeholder={t('Status')}
                                value={this.state.requestStatus}
                                onChange={value => this.onStatusFilterChange('status', value)}
                                onBlur={this.onBlurHandler}
                                onFocus={this.onFocusHandler}
                              />
                            </Form.Field>
                          </div>
                        )}
                      </Form.Group>
                    </Form>
                  </Grid.Row>
                }
                exportUrl={'events/v1/export-requests'}
                triggerExport={this.state.exportElements}
                clearSelection={this.state.clearSelection}
                canDelete={false}
                onDeleteRow={this.onDelete}
                onSaveRow={this.onSaveRow}
                onPageChange={this.handlePageChange}
                onFilterChange={this.handleFilterChange}
                canCreateNew={false}
                createNewButtonTitle={t('New Request')}
                onClickColApprovedReject={this.onClickColRequestId}
                loading={this.state.loading}
                unselectFirstRow
              ></TableView>
            </Grid.Row>
          </Grid>
        </div>

        {this.state.newRequestShown && <NewRequestView onClose={this.onNewItemClosed} {...this.props} />}

        {this.state.modalRequestForm && (
          <RequestForm history={history} onClose={this.onRequestFormClosed} request={buildRequestFormViewModel(this.state.selectedItem)} />
        )}

        {this.state.modalEventForm && (
          <EventForm mode="ViewDetails" onClose={this.onEventFormClosed} event={buildEventViewModel(this.state.openedEventForm)} />
        )}

        {this.state.cloningError && (
          <Modal
            className="user-modal merge-error-modal"
            size={'mini'}
            open={this.state.cloningError}
            closeOnEscape={true}
            onClose={() => this.setState({ cloningError: false })}
            closeOnDimmerClick={false}
          >
            <Modal.Content image>
              <Container>
                <Message
                  icon="exclamation circle"
                  className="error-message__style"
                  info
                  header={t('Clone Result')}
                  list={this.state.cloningErrorMessage}
                />
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <Button type="button" className="positive" onClick={() => this.setState({ cloningError: false })}>
                {t('OK')}
              </Button>
            </Modal.Actions>
          </Modal>
        )}

        {this.state.mergeError && (
          <Modal
            className="user-modal merge-error-modal"
            size={'mini'}
            open={this.state.mergeError}
            closeOnEscape={true}
            onClose={() => this.setState({ mergeError: false })}
            closeOnDimmerClick={false}
          >
            <Modal.Content image>
              <Container>
                <Message
                  icon="exclamation circle"
                  className="error-message__style"
                  info
                  header={t('Merge Result')}
                  list={this.state.mergeErrorMessage}
                />
              </Container>
            </Modal.Content>
            <Modal.Actions>
              <Button type="button" className="positive" onClick={() => this.setState({ mergeError: false })}>
                {t('OK')}
              </Button>
            </Modal.Actions>
          </Modal>
        )}
        {this.state.mergeShown && (
          <EventForm mode="Merge" event={buildEventViewModel(this.state.mergeEvent)} onClose={this.handleOnEventFormClose} />
        )}
      </>
    );
  }
}

export default withTranslation()(RequestsListPage);
